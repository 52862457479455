import { OccCodeSteadyPayload } from "../models/occ-code-steady.model";

export class LoadOccCodesSteady {
  public static readonly type = "[Occ Codes Steady] load steady occ codes";
}

export class AddOccCodeSteady {
  public static readonly type = "[Occ Codes Steady] add steady occ code";
  constructor(
    public payload: OccCodeSteadyPayload,
    public callbackSuccess: () => void,
    public callbackError: (error) => void
  ) {}
}

export class UpdateOccCodeSteady {
  public static readonly type = "[Occ Codes Steady] update steady occ code";
  constructor(
    public payload: OccCodeSteadyPayload,
    public callbackSuccess: () => void,
    public callbackError: (error) => void
  ) {}
}

export class RemoveOccCodeSteady {
  public static readonly type = "[Occ Codes Steady] remove steady occ code";
  constructor(
    public id: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
