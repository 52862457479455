import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { OccCodeSteadyPayload } from "./models/occ-code-steady.model";

@Injectable({
  providedIn: "root"
})
export class OccCodesSteadyService {
  
  private apiUrl: string;
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
    ) {
      this.apiUrl = this.env.getValueTransferState(API_URL);
    }

  getOccCodesSteady() {
    return this.http.get<any>(`${this.apiUrl}/app/steadyOccCodeRate`);
  }

  create(payload: OccCodeSteadyPayload) {
    return this.http.post<any>(`${this.apiUrl}/app/steadyOccCodeRate`, payload);
  }

  update(payload: OccCodeSteadyPayload) {
    return this.http.put<any>(`${this.apiUrl}/app/steadyOccCodeRate/${payload.id}`, payload);
  }

  remove(id: string) {
    return this.http.delete(`${this.apiUrl}/app/steadyOccCodeRate/${id}`);
  }
}
