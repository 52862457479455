import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { OccCodesSteadyService } from "../occ-codes-steady.service";
import { AddOccCodeSteady, LoadOccCodesSteady, RemoveOccCodeSteady, UpdateOccCodeSteady } from "./occ-codes-steady.actions";
import { OccCodesSteadyStateModel } from "./occ-codes-steady.model";

@State<OccCodesSteadyStateModel>({
	name: "occCodesSteady",
	defaults: AdminPagesStateDefaults
})
@Injectable()
export class OccCodesSteadyState {

	constructor(private occCodesService: OccCodesSteadyService) { }

	@Selector() static codes(state: OccCodesSteadyStateModel) { return state.items; }
	@Selector() static loading(state: OccCodesSteadyStateModel) { return state.loading; }
	@Selector() static saving(state: OccCodesSteadyStateModel) { return state.saving; }

	@Action(LoadOccCodesSteady)
	load(ctx: StateContext<OccCodesSteadyStateModel>) {
		ctx.patchState({ items: [], loading: true });

		return this.occCodesService.getOccCodesSteady().pipe(
			tap(
				(response) => {
					ctx.patchState({
						items: response.items,
						loaded: true,
						loading: false
					});
				}
			)
		);
	}

	@Action(AddOccCodeSteady)
	addOccCodeSteady(ctx: StateContext<OccCodesSteadyStateModel>, action: AddOccCodeSteady) {
		ctx.patchState({ saving: true });

		return this.occCodesService.create(action.payload).pipe(
			tap(
				(response) => {
					action.callbackSuccess();
					const state = ctx.getState();
					ctx.patchState({
						items: [response, ...state.items],
						saving: false 
					});
				},
				(error) => {
					action.callbackError(error);
					ctx.patchState({ saving: false });
				}
			)
		);
	}

	@Action(UpdateOccCodeSteady)
	updateOccCodeSteady(ctx: StateContext<OccCodesSteadyStateModel>, action: UpdateOccCodeSteady) {
		ctx.patchState({ saving: true });

		return this.occCodesService.update(action.payload).pipe(
			tap(
				(response) => {
					action.callbackSuccess();
					const state = ctx.getState();
					ctx.patchState({
						items: getUpdatedItems(response, state.items),
						saving: false
					});
				},
				(error) => {
					action.callbackError(error);
					ctx.patchState({ saving: false });
				}
			)
		);
	}

	@Action(RemoveOccCodeSteady)
	removeOccCodeSteady(ctx: StateContext<OccCodesSteadyStateModel>, action: RemoveOccCodeSteady) {
		return this.occCodesService.remove(action.id).pipe(
			tap(
				() => {
					action.callbackSuccess();
					const state = ctx.getState();
					const updated = state.items.filter(item => {
						return item.id !== action.id;
					});
					ctx.patchState({ items: updated });
				},
				(error) => {
					action.callbackError();
				}
			)
		);
	}
}
